import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
//import { Container as BlockContainer, Quote } from '../components/Blocks'
import { Container as BlockContainer } from '../components/Blocks'
//import { HeroCarousel, Hero } from '../components/Sections'
import {  Hero } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src="consumer/One-Stop-Shop.jpg" size={12} height={700} y={0} style={{ marginTop: '64px'}}>
    </Hero>
    <BlockContainer padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Consumer Retailing Tale</h1>
              <p>Sorosoro Ibaba Development Cooperative Consumer Retailing started last March 1969. It was funded by SIDC 59 incorporators who voluntarily contributed Two Hundred Pesos each to generate a a total capital of 11,800.00. It was used for the construction of a goods store that offered basic commodities needed by the community.</p>
              <p>55 years after, the cooperative have established, Seven (7) Coop Supermarts, eleven (10) Coopmart, two (2) 1 Clickmart, Thirty Five (41) One Stop Shop, Eight (10) Coopmart Jr. and one (1) Central Warehouse in Batangas, Laguna, Quezon, Oriental Mindoro, Occidental Mindoro, Palawan, Capiz, Aklan, Camarines Sur and Nueva Ecija to support the growing needs of its members.</p>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer padding={3}>
      <Container>
        <Row className="my-4 d-flex justify-content-center">
            <Col lg="7" className="d-flex flex-column justify-content-center p-3">
              <div className="lead mb-0 text-justify">
                <p>Coopmart, Supermart & Coopmart Jr. offers a wide variety of reasonably priced commodities: groceries, electrical supplies and agricultural inputs at affordable prices. It is equipped with electronic cash registers utilizing a computer program. As of now SIDC has 68  coop stores from Central and Southern Luzon, Bicol and Visayas.</p>
              </div>
            </Col>
            <Col lg="3" className="p-0">
               <img alt="ANNIVERSARY" className="img-fluid d-block" src={getAssetUrl('consumer/Coop Supermart.png')} />
               <img alt="ANNIVERSARY" className="img-fluid d-block" src={getAssetUrl('consumer/CoopMart.png')} />
               <img alt="ANNIVERSARY" className="img-fluid d-block" src={getAssetUrl('consumer/Coopmart Jr 2.png')} />
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col sm={{ order: 2 }} lg={{ size: 3, order: 1 }} className="p-0">
               <img alt="ANNIVERSARY" className="img-fluid d-block" src={getAssetUrl('consumer/1-Click-Mart.png')} />
            </Col>
            <Col sm={{ order: 1 }} lg={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3">
              <div className="lead mb-0 text-justify">
                <p>SIDC convenience stores are located at Petron, Balagtas Branch and Sorosoro Ilaya, Batangas City, Batangas. It is designed for SIDC Customers who are on the go and need to pick up just a few items.  It sells limited range of prepared ready to eat foods, bottled and beverage, household staples, tobacco products and periodicals.</p>
              </div>
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col lg="7" className="d-flex flex-column justify-content-center p-3">
              <div className="lead mb-0 text-justify">
                <p>The first DIY store of SIDC established last December 6, 2017 which provides material for construction and home improvement.</p>
              </div>
            </Col>
            <Col lg="3" className="p-0">
               <img alt="ANNIVERSARY" className="img-fluid d-block" src={getAssetUrl('consumer/KoopHardware.png')} />
            </Col>
        </Row>
        <Row className="my-4 d-flex justify-content-center">
            <Col sm={{ order: 2 }} lg={{ size: 3, order: 1 }} className="p-0">
               <img alt="ANNIVERSARY" className="img-fluid d-block" src={getAssetUrl('consumer/One Stop Shop.png')} />
            </Col>
            <Col sm={{ order: 1 }} lg={{ size: 7, order: 2 }} className="d-flex flex-column justify-content-center p-3">
              <div className="lead mb-0 text-justify">
                <p>SIDC newest type of Store that offers multitude Products and Services to its	customers from Financing, Consumer Retailing and Agri Products.</p>
              </div>
            </Col>
        </Row>
      </Container>
    </BlockContainer>
  </Layout>
)

export default IndexPage
